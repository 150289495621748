import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { useStaticQuery } from 'gatsby';

const Privacy = () => {
  const staticData = useStaticQuery(graphql`
    query SitePrivacyDataQuery {
      site {
        siteMetadata {
          privacy {
            introduction,
            contents {
              title
              items {
                subTitle
                number
                text
                subItems {
                  number
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout color="#0082f5">
      <SEO title="Privacy policy" />
      <h1 className="page-title">Privacy Policy</h1>
      {
        staticData &&
        <div className="tos-container">
          {
            staticData.site.siteMetadata.privacy.introduction.map((t, i) => (
              <p key={`${i}_`} dangerouslySetInnerHTML={{__html: t}} />
            ))
          }
          {
            staticData.site.siteMetadata.privacy.contents.map((c, i) => (
              <div key={`${i}_`}>
                <h2>{c.title}</h2>
                {
                  c.items.map((t, i) => (
                    <div key={`${i}_`}>
                      {
                        t.subTitle &&
                        <h3 dangerouslySetInnerHTML={{__html: t.subTitle}} />
                      }
                      {
                        t.number &&
                        <div className="numbered-text">
                          <span className="number">{t.number}</span>
                          <p className="text" dangerouslySetInnerHTML={{__html: t.text}} />
                        </div>
                      }
                      {
                        t.text && !t.number &&
                        <p dangerouslySetInnerHTML={{__html: t.text}} />
                      }
                      {
                        t.subItems &&
                        t.subItems.map((sub, i) => (
                          <div key={`${i}_`} style={{marginLeft: 40}}>
                            {
                              sub.subTitle &&
                              <h3 dangerouslySetInnerHTML={{__html: sub.subTitle}} />
                            }
                            {
                              sub.number &&
                              <div className="numbered-text">
                                <span className="number">{sub.number}</span>
                                <p className="text" dangerouslySetInnerHTML={{__html: sub.text}} />
                              </div>
                            }
                            {
                              sub.text && !sub.number &&
                              <p dangerouslySetInnerHTML={{__html: sub.text}} />
                            }
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      }
    </Layout>
  );
}

export default Privacy;